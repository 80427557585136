import { DateFormat, useFetch } from 'journey-ui'
import React from 'react'

interface Props {
  itemCard: ItemCard
}

interface NextAvailableData {
  start_at: string
  is_open: boolean
  end_at: string
}

export const ItemCardScheduleLine: React.FC<Props> = ({ itemCard }) => {
  const [nextAvailable, setNextAvailable] = useFetch<NextAvailableData>({
    url: `/api/schedule/${itemCard.schedule_hash_id}/next-available`,
    isAllowed: !!itemCard.schedule_hash_id,
  })

  if (!itemCard.schedule_hash_id || !nextAvailable || nextAvailable.is_open) {
    return null
  }

  const startAt = new Date(nextAvailable.start_at)
  const now = new Date()
  const isOnAnotherDay = startAt.getDate() !== now.getDate()

  return (
    <>
      <span>
        {isOnAnotherDay ? 'Available on ' : 'Available from '}
      </span>
      <DateFormat format={isOnAnotherDay ? 'eeee, h:mma' : 'h:mma'} value={nextAvailable.start_at} />
      <span> - </span>
      <DateFormat format="h:mma" value={nextAvailable.end_at} />
    </>
  )
}
