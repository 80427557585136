import { Button, Icon, Img, enhancedFetch, JourneyAppContext } from 'journey-ui'
import React, { useContext } from 'react'
import { awsImageUrl, createCartNotifications } from '../../helpers'
import { AppContext } from '../../contexts/AppContext'
import { ItemCardScheduleLine } from './ItemCardScheduleLine'

interface Props {
  itemCard: ItemCard
}

export const ItemCard: React.FC<Props> = ({ itemCard }) => {
  const [lowestPrice, highestPrice] = itemCard.price_ranges ?? []

  let prices: number[] = [lowestPrice, highestPrice].filter((p) => !!p)

  if (lowestPrice === highestPrice) {
    prices = [lowestPrice]
  }

  const priceLabels = prices.map((price) => (
    price === 0
      ? 'Free'
      : `${parseFloat(`${price / 100}`).toFixed(2)}`
  ))

  const { order, setOrder, setCartNotifications, setCartModalOpen } = useContext(AppContext)
  const { setAlert } = useContext(JourneyAppContext)

  const onClick = async (e) => {
    /* Button "href" prop will redirect us. */
    if (itemCard.is_editable) {
      return
    }

    try {
      const newOrder = await enhancedFetch<Order>('/api/order/add-item', {
        method: 'POST',
        body: {
          size_hash_id: itemCard.hash_id,
          quantity: 1,
          children: [],
          toppings: [],
        },
      })

      /* If we just added an item which has free toppings, show the cart. */
      if (!order?.items.some((i) => i.has_free_toppings) && newOrder.items.some((i) => i.has_free_toppings)) {
        setCartModalOpen(true)
      }

      setCartNotifications((prevCartNotifications) => createCartNotifications(prevCartNotifications, order, newOrder))
      setOrder(newOrder)
    } catch (error) {
      setAlert(error.message)
    }
  }

  return (
    <Button
      href={itemCard.is_editable ? `/item/${itemCard.slug}` : undefined}
      onClick={onClick}
      className="border bg-white hover:text-typography hover:border-gray-400 flex h-32 p-0 pl-3 items-start justify-between rounded w-full text-left"
    >
      <div className="p-2 h-full flex justify-center flex-col">
        <div>
          <div className="text-lg flex items-center space-x-2">
            <span className="heading-font lg:text-xl font-bold">{itemCard.name}</span>
            {/* {itemCard.is_hot && <Icon className="text-red-600" src={BiHot} />} */}
          </div>

          {itemCard.description && (
          <span className="text-left text-sm font-normal line-clamp-2 flex-grow">
            {itemCard.description}
          </span>
          )}

          <ItemCardScheduleLine itemCard={itemCard} />
        </div>
      </div>
      <div className="flex-shrink-0 justify-end min-w-[4rem] items-start h-full">
        <span className="flex-shrink-0 text-sm text-right text-black! bg-gray-100 p-1 rounded absolute top-1 right-1 font-bold bg-opacity-80 z-10">{priceLabels.join(' - ')}</span>
        {itemCard.picture && <Img size={{ w: 108, h: 148 }} className="max-w-[8rem] h-full" alt={itemCard.name} src={awsImageUrl(itemCard.picture)} />}
      </div>
    </Button>
  )
}
