import { Accordion, AccordionCaret, AccordionContent, AccordionHeader } from 'journey-ui'
import React from 'react'
import { SectionAccordion } from './SectionAccordion'
import { SectionContent } from './SectionContent'

interface Props {
  sections: Section[]
}

interface GroupedSection {
  name: string
  sections: Section[]
  hash_id: string
  slug: string
}

export function getGroupedSections(sections: Section[]) {
  const groupedSections: GroupedSection[] = []

  sections.forEach((section) => {
    const name = !section.group_name ? section.name : section.group_name
    let groupedSection = groupedSections.find((gs) => gs.name === name)

    if (!groupedSection) {
      groupedSection = {
        name,
        sections: [],
        slug: section.group_slug ? section.group_slug : section.slug,
        hash_id: section.group_name ? section.group_name : section.hash_id,
      }

      groupedSections.push(groupedSection)
    }

    groupedSection.sections.push(section)
  })

  return groupedSections
}

export const MenuSections: React.FC<Props> = ({ sections }) => {
  const groupedSections = getGroupedSections(sections)

  return (
    <>
      {groupedSections.map((groupedSection) => {
        const { sections, name, slug } = groupedSection

        /* Regular section display. */
        if (sections.length <= 1) {
          return <SectionAccordion key={sections[0].hash_id} section={sections[0]} />
        }

        /*
        * This is for nesting of sub sections under a section.
        * I.E "Subs" section has a "Hot Subs" and "Cold Subs" sub sections.
        */
        return (
          <Accordion className="flex-nowrap" openByDefault key={name}>
            <AccordionHeader className="section-header" id={slug}>
              <span>{name}</span>
              <AccordionCaret />
            </AccordionHeader>
            <AccordionContent className="p-1">
              {sections.map((section) => (
                <div className="py-2" key={section.hash_id}>
                  <div className="flex justify-center items-center heading-font text-md my-5">
                    <span className="border-b-2 border-dashed px-4">{section.name}</span>
                  </div>
                  <SectionContent section={section} />
                </div>
              ))}
            </AccordionContent>
          </Accordion>
        )
      })}
    </>
  )
}
