import { Button, DateFormat, Icon, MomentsAgo, ssrBuilder } from 'journey-ui'
import React, { useContext, useState } from 'react'
import ReactMarkdown from 'react-markdown'
import { Bell, CircleWavyCheck, Clock, Crown, HandWaving, PlusCircle, ShieldWarning } from 'phosphor-react'
import { AppPage } from '../components/AppPage'
import { MenuContext, MenuContextProps } from '../contexts/MenuContext'
import { MenuNav } from '../components/Menu/MenuNav'
import { BrandHeader } from '../components/BrandHeader'
import RewardsModal from '../components/Menu/RewardsModal'
import { AppContext } from '../contexts/AppContext'
import { DesktopFloatBtns } from '../components/Menu/DesktopFloatBtns'
import { MenuSections } from '../components/Menu/MenuSections'
import { redirectIfNoBrand, writeLog } from '../helpers'

type MenuPageJson = MenuContextProps
type Props = MenuPageJson

const MenuPage: React.FC<Props> = ({ brand, is_offline, sections, system_down_reason, vacation, menu_notification, is_open, description, next_time_open }) => {
  const [rewardModalOpen, setRewardModalOpen] = useState(false)
  const { setScheduleModalOpen, user } = useContext(AppContext)
  const contextValue = { brand, is_offline, menu_notification, rewardModalOpen, setRewardModalOpen, sections, next_time_open, is_open, description }

  return (
    <MenuContext.Provider value={contextValue}>
      <AppPage
        brand={brand}
        title={`${brand.name}'s Menu`}
        description={`${brand.name}'s at ${brand.location_display} official menu and online ordering.`}
        className="menu-page full-screen"
      >
        <BrandHeader className="min-h-40 p-4">
          <div className="z-20 flex flex-col bg-white border space-y-2 text-gray-700 p-6 text-black shadow-xl rounded-xl brand-menu-header-panel">
            <div className="border-b-2 border-dashed">
              <div className="text-xl font-bold heading-font">{description}</div>
              <div className="text-sm">{brand.location_display}</div>
            </div>

            <div className="space-y-1">
              {next_time_open ? (
                <div className="flex flex-col sm:flex-row space-y-2 sm:space-y-0 space-x-2 text-sm">
                  <div className="flex items-center space-x-2">
                    <Icon className="flex-shrink-0" src={Clock} />

                    <div>
                      <span>Open again</span>
                      <MomentsAgo className="px-1" value={next_time_open} />
                      <span>on</span>
                      <DateFormat className="pl-1" value={next_time_open} format="E, h:mma" />
                    </div>
                  </div>
                  { brand.has_scheduled_orders ? (
                    <Button className="size-sm px-4" onClick={() => setScheduleModalOpen(true)}>
                      <span>Schedule Order Later</span>
                      <Icon className="flex-shrink-0" src={Bell} />
                    </Button>
                  ) : null }
                </div>
              ) : (
                <div className="flex items-center space-x-2 text-sm">
                  {brand.is_onboarding ? (
                    <>
                      <Icon className="flex-shrink-0 text-red-500" src={ShieldWarning} />
                      <span>Online Ordering Coming Soon</span>
                    </>
                  ) : (
                    <>
                      <Icon className="flex-shrink-0" style={{ color: '#1fdb1f' }} src={CircleWavyCheck} />
                      <span>Online & Taking Orders Now</span>
                    </>
                  )}
                </div>
              )}

              {brand.has_rewards ? (
                <div className="flex  flex-col sm:flex-row space-y-2 sm:space-y-0 justify-between space-x-2 text-sm">
                  <div className="flex items-center space-x-2">
                    <Icon className="flex-shrink-0" src={PlusCircle} />
                    <span>
                      {`Earn ${brand.reward_points_per_order} reward points per order`}
                    </span>
                  </div>
                  <Button className="size-sm px-4" onClick={() => setRewardModalOpen(true)}>
                    <span>Our Rewards</span>
                    <Icon className="flex-shrink-0" src={Crown} />
                  </Button>
                </div>
              ) : null}

              {user ? (
                <div className="flex justify-between items-center">
                  <div className="text-sm space-x-2 flex items-center max-w-[200px]">
                    <Icon className="animate-bounce flex-shrink-0" src={HandWaving} />
                    <span>{`Welcome back ${user.name.split(' ')[0]}, reorder your favorite again.`}</span>
                  </div>
                  <Button className="size-sm px-4" href="/user/orders">
                    Reorder
                  </Button>
                </div>
              ) : null}
            </div>
          </div>
        </BrandHeader>

        <MenuNav />
        {system_down_reason && (
          <div className="bg-red-500 py-4 text-white text-lg flex justify-center items-center">
            {system_down_reason}
          </div>
        )}
        {vacation && (
          <div className="flex flex-col md:flex-row px-2 md:px-0 justify-center items-center font-bold my-4 space-x-1 text-lg">
            <span>We are temporarily offline from</span>
            <DateFormat value={vacation.start_date} format="M/d/y" />
            <span>to</span>
            <DateFormat value={vacation.end_date} format="M/d/y" />
          </div>
        )}
        {menu_notification && (
          <div className="flex justify-center items-center my-4 font-bold space-x-2">
            <ReactMarkdown>{menu_notification}</ReactMarkdown>
          </div>
        )}

        {is_offline && (
          <div className="flex justify-center items-center my-4">
            <span>
              {`Sorry, our online ordering is temporarily offline, please call in your order directly at ${brand.phone_number}.`}
            </span>
          </div>
        )}
        <div className="w-full">
          <MenuSections sections={sections} />
        </div>

        <DesktopFloatBtns />

        <RewardsModal />
      </AppPage>
    </MenuContext.Provider>
  )
}

export default MenuPage

const menuPages = {}

async function getMenuPageData(ctx, fetcher) {
  const menuPage = menuPages[ctx.req.headers.host]
  const now = Date.now()

  if (!menuPage || menuPage.last_fetched_at < (now - 60_000)) {
    const menuPageJson = await fetcher(`/api/brand/${ctx.req.headers.host}/menu-page`)

    menuPages[ctx.req.headers.host] = {
      last_fetched_at: now,
      data: menuPageJson,
    }
  }

  return menuPages[ctx.req.headers.host].data
}

export async function getServerSideProps(ctx) {
  return ssrBuilder(ctx)
    .setProps((fetcher) => getMenuPageData(ctx, fetcher))
    .onError(redirectIfNoBrand)
    .get()
}
