import classNames from 'classnames'
import { Icon, Journey, Modal, ModalContent, ModalHeader } from 'journey-ui'
import { At, CircleWavyCheck, CurrencyCircleDollar, Gift } from 'phosphor-react'
import React, { useContext } from 'react'
import { MenuContext } from '../../contexts/MenuContext'

const Block = Journey.div('flex space-x-4 items-center p-2')
const RewardIcon = (props) => <Icon {...props} className={classNames(props.className, 'w-10 h-10 flex-shrink-0 text-primary')} />

const RewardsModal: React.FC = () => {
  const { brand, rewardModalOpen, setRewardModalOpen } = useContext(MenuContext)
  /* Min orders required to use the reward system. */
  const minOrders = Math.ceil(brand.min_reward_points / brand.reward_points_per_order)
  /* How many points do we get if we did do all those orders? */
  const rewardedPoints = minOrders * brand.reward_points_per_order
  /* How much is the rewarded points in actual cash. */
  const cashAmount = parseFloat(`${rewardedPoints / 100}`).toFixed(2)
  /* Is there a minimum the orders sub total has to be to qualify? */
  const subTotalThreshold = `$${parseFloat(`${brand.reward_sub_total_threshold / 100}`).toFixed(2)}`

  return (
    <Modal isOpen={rewardModalOpen} onClose={() => setRewardModalOpen(false)}>
      <ModalHeader>
        Rewards
      </ModalHeader>

      <ModalContent>
        <div className="w-full flex justify-center items-center py-4">
          <span className="text-2xl font-bold">
            {`Place ${minOrders} Orders Online, Get ${rewardedPoints} points!`}
          </span>
          <span className="text-sm text-green-500 px-1">
            {`($${cashAmount})`}
          </span>
        </div>

        <Block>
          <RewardIcon src={CurrencyCircleDollar} />
          <span>
            {`Every online order placed with a subtotal over ${subTotalThreshold} will earn you points.
            After ${minOrders} orders, you'll be able to redeem your ${rewardedPoints} points for discounts.`}
          </span>
        </Block>
        <Block>
          <RewardIcon src={Gift} />
          <span>When you place your online order, we will ask you for your email address which is used as the identifier for your reward points for next time!</span>
        </Block>
        <Block>
          <RewardIcon src={At} />
          <span>Simply use the same email address each time you order and we'll automatically keep track your rewards.</span>
        </Block>
      </ModalContent>
    </Modal>
  )
}

export default RewardsModal
