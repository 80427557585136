import { createContext, Dispatch, SetStateAction } from 'react'

export interface MenuContextProps {
  brand: Brand
  description: string
  is_open: boolean
  is_offline: boolean
  sections: Section[]
  next_time_open: string
  menu_notification: string
  system_down_reason?: string
  rewardModalOpen: boolean
  setRewardModalOpen: Dispatch<SetStateAction<boolean>>
  vacation?: Vacation
}

export const MenuContext = createContext<MenuContextProps>({} as MenuContextProps)
