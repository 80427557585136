import React, { useContext, useEffect, useRef, useState } from 'react'
import { A, Button, Select, SelectButton, SelectButtonCaret, SelectDropdown } from 'journey-ui'
import { useWindowWidth } from '@react-hook/window-size'
import { MenuContext } from '../../contexts/MenuContext'
import { getGroupedSections } from './MenuSections'

interface Props {

}

export const MenuNav: React.FC<Props> = () => {
  const { sections } = useContext(MenuContext)
  const [open, setOpen] = useState(false)
  const [hiddenSectionNames, setHiddenSectionNames] = useState<string[]>([])
  const sectionListRef = useRef<HTMLDivElement | null>(null)
  const groupedSections = getGroupedSections(sections)
  const screenWidth = useWindowWidth()

  useEffect(() => {
    const sectionListEle = sectionListRef.current
    // @ts-ignore
    const hiddenLinks = [...sectionListEle.querySelectorAll('a')]
      .filter((node) => {
        const { x, width } = node.getBoundingClientRect()
        const { width: parentWidth } = node.parentElement.getBoundingClientRect()

        /* I see a px-1 and px-4 that is applying padding so 48 */
        /* We add the 72 to make it more sensitive as the calculation is not being 100% accurate to the view port, which may be due to some padding/box-sizing offset. */
        return (parentWidth - 72) < (x + width)
      })

    setHiddenSectionNames(hiddenLinks.map((hiddenLink) => hiddenLink.text))
  }, [screenWidth])

  const onMoreClick = () => setOpen(true)

  return (
    <div className="sticky z-20 top-0 flex flex-nowrap w-full h-14 inset-0 bg-primary text-primary-contrast px-1 justify-between">
      <div className="flex flex-nowrap px-4 space-x-4 justify-start items-center overflow-hidden w-full" ref={sectionListRef}>
        {groupedSections.map((groupedSection) => (
          <A className="flex heading-font justify-center flex-grow flex-shrink-0 text-white font-bold" key={groupedSection.hash_id} href={`#${groupedSection.slug}`}>
            {groupedSection.name}
          </A>
        ))}
      </div>

      {hiddenSectionNames.length > 0 ? (
        <Select onOpen={onMoreClick} onClose={() => setOpen(false)} isOpen={open} className="flex items-center">
          <SelectButton className="heading-font text-white border-none">
            <span>More</span>
            <SelectButtonCaret />
          </SelectButton>
          <SelectDropdown>
            {groupedSections.filter((s) => hiddenSectionNames.includes(s.name)).map((groupedSection) => (
              <Button key={groupedSection.hash_id} href={`#${groupedSection.slug}`} onClick={() => setOpen(false)} className="heading-font border-none w-full rounded-none">
                {groupedSection.name}
              </Button>
            ))}
          </SelectDropdown>
        </Select>
      ) : null}
    </div>
  )
}
