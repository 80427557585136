import { Col, Row } from 'journey-ui'
import React from 'react'
import { ItemCard } from './ItemCard'

export const SectionContent = ({ section }) => (
  <>
    {section.description ? (
      <div className="py-4 justify-center flex items-center text-center">
        {section.description}
      </div>
    ) : null}
    <Row>
      {section.item_cards.map((itemCard) => (
        <Col lg={4} sm={6} xs={12} key={itemCard.name}>
          <ItemCard itemCard={itemCard} />
        </Col>
      ))}
    </Row>
  </>
)
