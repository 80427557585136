import React from 'react'
import { Accordion, AccordionCaret, AccordionContent, AccordionHeader } from 'journey-ui'
import { SectionContent } from './SectionContent'

interface Props {
  section: Section
}

export const SectionAccordion: React.FC<Props> = ({ section }) => (
  <Accordion className="flex-nowrap" openByDefault>
    <AccordionHeader className="section-header" id={section.slug}>
      <span>{section.name}</span>
      <AccordionCaret />
    </AccordionHeader>
    <AccordionContent className="p-1">
      <SectionContent section={section} />
    </AccordionContent>
  </Accordion>
)
