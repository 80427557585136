import { Button, Icon } from 'journey-ui'
import React, { useContext } from 'react'
import classNames from 'classnames'
import { CaretDoubleRight, ShoppingCart } from 'phosphor-react'
import { useRouter } from 'next/router'
import { AppContext } from '../../contexts/AppContext'
import { CartItemCount } from './CartItemCount'
import { useCheckoutClick } from '../../hooks/useCheckoutClick'

export const DesktopFloatBtns = () => {
  const { setCartModalOpen, order, brand, seenUpsell, setSeenUpsell, setOrder, cartNotifications } = useContext(AppContext)
  const router = useRouter()
  const onCheckoutClick = useCheckoutClick()

  if ((order?.items ?? []).length <= 0) {
    return null
  }

  return (
    <div className="sticky bottom-0 p-2 w-full hidden md:flex justify-center z-40 space-x-2">
      <Button
        className={classNames(
          'space-x-2 items-center flex justify-between bg-gray-100 transition-colors duration-500 p-3 rounded-full overflow-visible',
        )}
        onClick={() => setCartModalOpen(true)}
      >
        <span>Cart</span>
        <div>
          <CartItemCount className="top-1 right-1 pt-0.5" />
          <Icon src={ShoppingCart} />
        </div>
      </Button>
      <Button className="primary" onClick={onCheckoutClick}>
        <span>Checkout</span>
        <Icon src={CaretDoubleRight} />
      </Button>
    </div>
  )
}
